import React from "react";
import followship from "../../images/landing/followup.png";

const Season = ({ setIsOpen }) => {
  return (
    <div className="col-12 season">
      <div className="row no-gutters">
        <div className="col-md-2"></div>
        <div className="col-md-5 followup-mob">
          <img className="followup-img" src={followship} alt="" />
        </div>
        <div className="col-md-5">
          <h2>START EARNING WITH OUR</h2>
          <h1>FELLOWSHIP PROGRAM</h1>
          <p className="">
            FELLOWSHIP PROGRAM IS TO BOOST THE BENIFIT FOR BOTH INVESTORS AND
            THE HOTHEAD PLAYERS. ENROLL AND BUILD AN IMPRESSIVE PROFILETO EARN!
          </p>
          <button onClick={setIsOpen} className="btn active">
            GET GAME
          </button>
          <a
            href="https://battle-craft-game.gitbook.io/battlepaper/battle-paper/fellowship-program"
            target="_blank"
            rel="noreferrer"
            className="btn"
          >
            HOW IT WORKS
          </a>
        </div>
        <div className="col-md-5 followup-web">
          <img className="followup-img" src={followship} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Season;
