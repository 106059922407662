import React from "react";

import audit from "../../images/audit/audit.png";

function GrabNft() {
  return (
    <div className="col-12 limited">
      <div className="row no-gutters main">
        <div className="col-md-5 sub-branch order-md-1 order-sm-12 order-12">
          <h2>LIMITED NFTs</h2>
          <h1>GRAB YOURS NOW</h1>
          <p>SKYVERSE IS AN EXPERIENCE FULL OF VR ADVENTURE. USE A VR HEADSET FOR BEST EXPERIENCE!</p>
          <a rel="noreferrer" href="https://forms.gle/R8wytYNrkazuzL5Y7" target="_blank" className="btn">
            REGISTER NOW
          </a>
          <a
            rel="noreferrer"
            href="https://battlecraft.medium.com/presenting-nft-early-access-program-23e5d36a1d79"
            target="_blank"
            className="btn btn-yellow"
          >
            READ MORE
          </a>
        </div>
        <img alt="" className="col-md-7 circle order-md-12 order-sm-1 order-1" src={audit} />
      </div>
    </div>
  );
}

export default GrabNft;
