import React, { useState } from "react";
import Navbar from "../components/topNav";
import lockman from "../images/lockman.png";
import moment from "moment";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AUTH_KEY } from "../constants/global";

function AuthPage() {
  const [isNav] = useState(true);
  const [password, setPassword] = useState("");

  let history = useHistory();

  const handleAuth = (e) => {
    axios
      .post("https://3sdg7ystun2me2kbejnpbu5gue0bqwcq.lambda-url.ap-south-1.on.aws/", { key1: password })
      .then((res) => {
        if (res?.data) {
          localStorage.setItem(AUTH_KEY, moment.utc().add(1, "days").valueOf());
          history.push("/");
        }
      })
      .catch((err) => {
        // console.log('err',);
      });
  };

  return (
    <div className="row auth no-gutters">
      <Navbar isNav={isNav} />
      <div className="main">
        <div className="first-div">
          <h5>LIMITED ACCESS ONLY</h5>
          <h1>PASSWORD PROTECTED</h1>
          <p>YOU'LL NEED A PASSWORD TO CONTINUE TO THIS WEBSITE</p>
          <div className="input-group mb-3">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder=""
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button onClick={handleAuth} className="input-group-text btn" id="basic-addon2">
                ENTER WEBSITE
              </button>
            </div>
          </div>
        </div>
        <img width="26%" src={lockman} alt="" />
      </div>
    </div>
  );
}

export default AuthPage;
