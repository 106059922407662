import React from "react";
import { Element } from "react-scroll";

import ressos from "../../images/landing/ressos.svg";
import hacken from "../../images/landing/hacken.svg";
import rd from "../../images/landing/rd.svg";
import hackenHover from "../../images/landing/hacken-hover.png";
import rdHover from "../../images/landing/auditors-hover.png";
import legalHover from "../../images/landing/legal-hover.png";
import eyeHover from "../../images/landing/eye-hover.png";

const Audit = () => {
  return (
    <>
      <Element id="audit" className="col-12 audit">
        <h1 className="px-2">AUDITED AND SECURED BY:</h1>
        <div className="row sub-audit">
          <div className="imageBox">
            <div class="imageInn">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1UhhHjoGWggCUT5HNyCcDDx19t8wvE7U3/view"
              >
                {" "}
                <img src={hacken} alt="" />
              </a>
              {/* <button className='btn'>AUDIT REPORT</button> */}
            </div>
            <div class="hoverImg">
              <img src={hackenHover} alt="Profile" />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1UhhHjoGWggCUT5HNyCcDDx19t8wvE7U3/view"
              >
                <img alt="" src={eyeHover} className="eye" />
              </a>
            </div>
          </div>
          <div className="imageBox">
            <div class="imageInn">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1Y6WaKxFhOgg1wE6JaW2J9rIQQ7jdKBO6/view?usp=sharing"
              >
                {" "}
                <img src={rd} alt="" />
              </a>
            </div>
            <div class="hoverImg">
              <img src={rdHover} alt="Profile" />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1Y6WaKxFhOgg1wE6JaW2J9rIQQ7jdKBO6/view?usp=sharing"
              >
                <img src={eyeHover} className="eye" alt="" />
              </a>
            </div>
          </div>
          <div className="imageBox">
            <div class="imageInn">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1TCaJj-Q2nrJjCPlOKG4aBbwE5nRq9TQz/view?usp=sharing"
              >
                <img src={ressos} alt="" />
              </a>
            </div>
            <div class="hoverImg">
              <img src={legalHover} alt="Profile" />
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1TCaJj-Q2nrJjCPlOKG4aBbwE5nRq9TQz/view?usp=sharing"
                rel="noreferrer"
              >
                <img src={eyeHover} className="eye" alt="" />
              </a>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default Audit;
