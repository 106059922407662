import React from "react";
import { Card } from "react-bootstrap";

import RankBar from "./RankBar";
import XPCoins from "./XPCoins";
import { getCurrentLevelTotalPoints } from "./utils/getCurrentLevelTotalPoints";
// import { calculateTotalXP } from "./utils/calculateTotalXP";
import Avatar from "../../images/leaderboard/avatar.svg";

const TopRank = ({ data }) => {
  return (
    <div className="top-rank">
      <Card className="primary-card">
        <Card.Body>
          <div className="profile">
            <img src={data?.profileImage ? `data:image/png;base64, ${data?.profileImage}` : Avatar} alt={data?.name} />
            <p className="name">{data?.name}</p>
            <p className="id">ID: {data?.id}</p>
          </div>

          <div className="rank">
            <p className="text-right text-muted mb-2">
              {data?.current_level_points}/{getCurrentLevelTotalPoints(data?.level)}
            </p>

            <RankBar level={data?.level} current_level_points={data?.current_level_points} />

            <XPCoins value={data?.currentSeasonXP} />
          </div>
        </Card.Body>
        <div className="rank-badge">
          <img src={data?.rank_badge} alt="" />
        </div>
      </Card>
    </div>
  );
};

export default TopRank;
