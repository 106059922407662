import { useEffect } from "react";
import Axios from "../config/axios";
import { useState } from "react";

function useFetchLeaderBoardPlayer() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setIsLoading(true);
    Axios.get("/user/leaderboard", {
      params: {
        fields:
          "username,lastLoginTime,playerActiveLevel, playerLevelPoints, uniqueid, userDisplayImage, currentSeasonXP",
        sort: "-currentSeasonXP,-playerActiveLevel,-playerLevelPoints,-totalnumberofkills,-lastLoginTime",
        limit,
      },
    })
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [limit, refresh]);

  const refetch = () => {
    setRefresh(!refresh);
  };

  const loadMore = () => {
    setLimit(limit + 10);
  };

  return { data, isLoading, error, refetch, loadMore, limit };
}

export default useFetchLeaderBoardPlayer;
