import React from "react";
import { useMemo } from "react";
import { clsx } from "clsx";
import LevelShield from "../../images/leaderboard/rank-shield.svg";
import { getCurrentLevelTotalPoints } from "./utils/getCurrentLevelTotalPoints";

function RankBar({ level = 1, current_level_points = 0 }) {
  const getBarWidth = useMemo(() => {
    return (current_level_points / getCurrentLevelTotalPoints(level)) * 100;
  }, [current_level_points, level]);

  const getXpClassName = useMemo(() => {
    if (getBarWidth) {
      if (getBarWidth > 75) return "full-xp";
      else if (getBarWidth > 50) return "high-xp";
      else if (getBarWidth > 25) return "medium-xp";
      return "low-xp";
    }
  }, [getBarWidth]);

  return (
    <div className="rank-bar">
      <div
        className={clsx("bar", getXpClassName)}
        style={{ width: `${getBarWidth}%` }}
      >
        <div className="level-count">
          <img src={LevelShield} alt="" />
          <span>{level ?? 0}</span>
        </div>
      </div>
    </div>
  );
}

export default RankBar;

// red yellow blue green
