import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import logo from "../images/logo.svg";
import voilance from "../images/voilance.svg";
import info from "../images/infoimage.svg";
import medium from "../images/medium.svg";
import twitter from "../images/twitter.svg";
import telegram from "../images/telegram.svg";
import instagram from "../images/instagram.svg";
import game from "../images/game.svg";
import "react-responsive-modal/styles.css";
import GetTheGameModal from "./common/GetTheGameModal";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <section className="footer col-12">
        <footer>
          <div className="container-fluid p-4">
            <div className="first-link-div mt-5">
              <ul className="list-unstyled nav mb-0">
                <li className="first">
                  <Link to="/marketplace">NFT MARKETPLACE</Link>
                </li>
                <span className="line-foot"></span>
                <li onClick={() => setIsOpen(true)}>
                  <Link to="/">DOWNLOAD NOW</Link>
                </li>
                <span className="line-foot"></span>
                <li>
                  <a
                    href="https://battle-craft-game.gitbook.io/battlepaper/"
                    target="_blank"
                    rel="noreferre noreferrer"
                  >
                    BATTLE PAPER
                  </a>
                </li>
                {/* <span className="line-foot"></span>
                <li>
                  <a
                    href="https://drive.google.com/open?id=1THxtosDGbuH_bRD97-aSKxIcTlTj9jMT&authuser=salman95altaf%40gmail.com&usp=drive_fs"
                    target="_blank"
                    rel="noreferre noreferrer"
                  >
                    PITCH DECK
                  </a>
                </li> */}
                <span className="line-foot"></span>
                <li>
                  <a
                    href="https://drive.google.com/drive/folders/1QVAdnWrkRuPEpDA9N2l8csuedGAp3Auw?usp=sharing"
                    target="_blank"
                    rel="noreferre noreferrer"
                  >
                    MEDIA KIT
                  </a>
                </li>
              </ul>
              <hr />
            </div>
            <div className="footle-last">
              <div className="social-images">
                <a target="_blank" href="https://t.me/BattleCraftAnn" rel="noreferrer">
                  {" "}
                  <img className="ml-0" src={telegram} alt="" />
                </a>
                <a target="_blank" href="https://twitter.com/BattleCraftNFT" rel="noreferrer">
                  <img src={twitter} alt="" />
                </a>
                <a target="_blank" href="https://battlecraft.medium.com" rel="noreferrer">
                  <img src={medium} alt="" />
                </a>
                <a target="_blank" href="https://discord.com/invite/ekgvensjb6" rel="noreferrer">
                  <img src={game} alt="" />
                </a>
                <a target="_blank" href="https://www.instagram.com/battlecraftspace/" rel="noreferrer">
                  <img src={instagram} alt="" />
                </a>
              </div>
              <div className="logo-div">
                <img className="logo" src={logo} alt="" />
                <div>
                  <span>GREY SPACE OÜ </span>
                  <p>HARJU MAAKOND, TALLINN, KRISTIINE LINNAOSA, SEEBI TN 1-1501, 11316, ESTONIA</p>

                  <span className="last-span">Copyrights 2022, Battlecraft. All rights reserved</span>
                </div>
              </div>
              <div className="marginMobile">
                <img className="danger" style={{ display: "block", marginBottom: "5px" }} src={voilance} alt="" />
                <img src={info} alt="" />
              </div>
            </div>
          </div>
        </footer>
      </section>
      <GetTheGameModal isOpen={isOpen} toggleOpen={toggleOpen} />
    </>
  );
};

export default withRouter(Footer);
