import React from "react";
import { Modal } from "react-responsive-modal";
import close from "../../images/close.png";
import GetGameLinks from "./GetGameLinks";

function GetTheGameModal({ isOpen, toggleOpen }) {
  return (
    <Modal
      classNames={{ modal: "download" }}
      open={isOpen}
      onClose={toggleOpen}
    >
      <img onClick={toggleOpen} className="close-img" src={close} alt="" />
      <h1>GET THE GAME</h1>
      <p>DOWNLOAD BATTLE CRAFT TO PLAY ON ANY OF THE SUPPORTED PLATFORMS.</p>
      <div className="sub-main">
        <GetGameLinks />
      </div>
    </Modal>
  );
}

export default GetTheGameModal;
