import React, { useState, useEffect } from "react";
import Banner from "../components/landing/banner";
import Game from "../components/landing/game";
import NFT from "../components/landing/nft";
import RoadMap from "../components/landing/roadMap";
import Navbar from "../components/topNav";
import close from "../images/close.png";
import Footer from "../components/footer";
import Sound from "react-sound";
import music from "../images/music.wav";
import Audit from "../components/landing/audit";
import Trailer from "../components/landing/trailer";
import Discord from "../components/landing/discord";
import Season from "../components/landing/season";
// import Team from "../components/landing/team";
import Guardians from "../components/landing/guardians";
import GetGame from "../components/landing/getGame";
import DeathMatch from "../components/landing/deathMatch";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../constants/global";
import GetGameLinks from "../components/common/GetGameLinks";
import LeaderBoardBanner from "../components/leaderBoardBanner/LeaderBoardBanner";
import AppDeletionPolicy from "./AppDeletionPolicy";

const Landing = () => {
  const [isPlay, setIsPlay] = useState(false);
  const [isNav, setIsNAv] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onopenModal = () => {
    setIsOpen(true);
    console.log("working");
  };
  useEffect(() => {
    function handleResize() {
      if (window.pageYOffset > 128) {
        setIsNAv(true);
      } else {
        setIsNAv(false);
      }
    }

    window.addEventListener("scroll", handleResize);
    return () => window.removeEventListener("scroll", handleResize);
  }, []);
  return (
    <>
      <LeaderBoardBanner />
      {/* <div className='row no-gutters fixed-img'>
    </div>   */}
      <div className="row no-gutters landing">
        {isPlay && (
          <Sound
            url={music}
            playStatus={Sound.status.PLAYING}
            playFromPosition={300 /* in milliseconds */}
            // onLoading={this.handleSongLoading}
            // onPlaying={this.handleSongPlaying}
            onFinishedPlaying={() => {
              setIsPlay(false);
            }}
          />
        )}
        <Navbar isNav={isNav} />
        <Modal classNames={{ modal: "download" }} open={isOpen} onClose={() => setIsOpen(false)}>
          <img onClick={() => setIsOpen(false)} className="close-img" src={close} alt="" />
          <h1>GET THE GAME</h1>
          <p>DOWNLOAD BATTLE CRAFT TO PLAY ON ANY OF THE SUPPORTED PLATFORMS.</p>
          <div className="sub-main">
            <GetGameLinks />
          </div>
        </Modal>
        <Banner setIsOpen={onopenModal} />

        <Game />
        <Audit />
        <Trailer />
        <NFT />
        <DeathMatch />
        <Discord />
        <Season setIsOpen={onopenModal} />
        {/* <Team /> */}
        <Guardians />
        {/* <Partners /> */}
        <RoadMap />
        <GetGame />
        {/* <GameMode /> */}
        {/* <VrSupport />
      <Battle /> */}
        {/* <button className='btn music-btn' onClick={()=> setIsPlay(!isPlay)}>
        <img src={icon} alt=""/> {isPlay ? 'ON': 'OFF'}</button> */}
        {/* <Space /> */}
        {/* <IDOPlatform /> */}
        {/* <div id="sceneContainer" style="height: 100%; width: 100%"></div> */}
        {/* <AboutGame /> */}
        {/* <Wapon /> */}
        {/* <PartnerShips /> */}
        {/* <GetJet />
      <Social />
      <RoadMap />
      <Skippers />
      {/* < */}
      <AppDeletionPolicy />
        <Footer />
      </div>
    </>
  );
};

export default Landing;
