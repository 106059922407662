import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import RankShield from "../../images/leaderboard/rank-shield-secondary.svg";
import RankBar from "./RankBar";
import XPCoins from "./XPCoins";
import { getCurrentLevelTotalPoints } from "./utils/getCurrentLevelTotalPoints";
// import { calculateTotalXP } from "./utils/calculateTotalXP";
import Avatar from "../../images/leaderboard/avatar.svg";

function Rank({ data }) {
  return (
    <Card className="primary-card rank-list-card mb-3">
      <Card.Body className="px-0">
        <Row className="rank-list">
          <Col xs={{ span: 4, order: 1 }} sm={{ span: 3, order: 1 }} md={{ span: 1, order: 1 }}>
            <div className="rank-count">
              <img src={RankShield} alt="" />
              <span>{data?.rank}</span>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 3 }} sm={{ span: 6, order: 2 }} md={{ span: 3, order: 2 }}>
            <div className="profile">
              <img
                src={data?.profileImage ? `data:image/png;base64, ${data?.profileImage}` : Avatar}
                alt={data?.name}
              />
              <div>
                <p className="name">{data?.name}</p>
                <p className="id">ID: {data?.id}</p>
              </div>
            </div>
          </Col>

          <Col xs={{ span: 12, order: 4 }} sm={{ span: 12, order: 4 }} md={{ span: 5, order: 3 }}>
            <div className="d-flex align-items-center rankbar-wrapper">
              <RankBar level={data?.level} current_level_points={data?.current_level_points} />

              <p className="text-right text-muted m-0 ml-3">
                {data?.current_level_points}/{getCurrentLevelTotalPoints(data?.level)}
              </p>
            </div>
          </Col>

          <Col xs={{ span: 8, order: 2 }} sm={{ span: 3, order: 3 }} md={{ span: 2, order: 4 }}>
            <XPCoins value={data?.currentSeasonXP} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Rank;
