const styles = {
    container: {
      color: 'black',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
    title: {
      color: '#66b6ee',
      textAlign: 'center',
      marginTop: '30px',
      fontSize: '2rem',
    },
    paragraph: {
      textAlign: 'left',
      marginTop: '10px',
    },
    heading: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      marginTop: '10px',
    },
    topMargin: {
      marginTop: '10px',
    },
  };
  
  const AppDeletionPolicy = () => {
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>Data Deletion Policy for BattleCraft Game</h1>
        <p style={styles.paragraph}>
          At BattleCraft, we value your privacy and strive to provide transparency regarding the handling of your personal data and game account information. This Deletion Policy outlines the procedures for users who wish to delete their data and game account from BattleCraft.
        </p>
  
        <h3 style={styles.heading}>1. Deleting Your Account</h3>
        <p>
          If you wish to delete your BattleCraft account and associated data, you can do so by following these steps:
        </p>
        <ul>
          <li>Open the BattleCraft app on your device</li>
          <li>Navigate to the settings or account management section</li>
          <li>Look for the option labeled "Delete Account" or similar</li>
          <li>Follow the prompts to confirm your decision to delete your account.</li>
          <li>Your account and associated data will be permanently deleted from our servers.</li>
        </ul>
  
        <h3 style={styles.heading}>2. Consequences of Deleting Your Account</h3>
        <p>
          Once you delete your BattleCraft account, you will lose access to all game progress, virtual items, in-game currency, and any other data associated with your account.
        </p>
        <p style={styles.topMargin}>
          Your username will become available for others to use.
        </p>
        <p>
          You will no longer receive any communication or updates from BattleCraft unless you create a new account in the future.
        </p>
  
        <h3 style={styles.heading}>3. Data Retention and Deletion</h3>
        <p style={styles.topMargin}>
          We will promptly delete all personal data and game account information upon receiving a valid deletion request.
        </p>
        <p>
          Deleted data may persist in our backup systems for a limited period before being permanently erased. However, it will not be accessible or used for any purpose.
        </p>
        <p style={styles.topMargin}>
          We may retain certain anonymized or aggregated data for analytical purposes, but this data will not be traceable back to individual users.
        </p>
  
        <h3 style={styles.heading}>4. Contact Us:</h3>
        <p style={styles.topMargin}>
          If you have any questions or concerns regarding the deletion of your BattleCraft account or data, please contact our customer support team at <a href="mailto:Connect@battlecraft.space">Connect@battlecraft.space</a>.
          We're here to assist you and ensure a smooth deletion process.
        </p>

        <h3 style={styles.heading}>5. Changes to this Deletion Policy:</h3>
        <p style={styles.topMargin}>
        BattleCraft reserves the right to update or modify this Deletion Policy at any time. We
encourage you to review this policy periodically for any changes. Your continued use of
the BattleCraft game app after the posting of changes constitutes acceptance of those
changes.

        </p>  

        <p style={styles.topMargin}>
       
        By using the BattleCraft game app, you agree to abide by this Deletion Policy and the
Terms of Service. If you do not agree with any aspect of this policy, please refrain from
using the BattleCraft app.
        </p>  
        

 
   






       
     

      </div>
    );
  };
  
  export default AppDeletionPolicy;
  