import Navbar from "../components/topNav";
import Footer from "../components/footer";
import GetGame from "../components/landing/getGame";
import { Col, Row } from "react-bootstrap";
import TotalReward from "../components/gameLeaderBoard/TotalReward";
import CompetitionEnds from "../components/gameLeaderBoard/CompetitionEnds";
import TopRank from "../components/gameLeaderBoard/TopRank";
import Rank from "../components/gameLeaderBoard/Rank";
import useShowNav from "../hook/useShowNav";
import logo from "../images/logo.svg";

import Rank1 from "../images/leaderboard/rank-1.png";
import Rank2 from "../images/leaderboard/rank-2.png";
import Rank3 from "../images/leaderboard/rank-3.png";
import useFetchLeaderBoardPlayer from "../hook/useFetchLeaderBoardPlayer";
import Button from "../components/common/button/Button";
import { useMemo } from "react";
import { MAX_LEADER_BOARD_COUNT } from "../constants/global";
import { Link } from "react-router-dom";
import BannerSeason1 from "../images/leaderboard/banner.png";
import BannerSeason1Mobile from "../images/leaderboard/banner-mobile.png";
import useViewportWidth from "../hook/useViewport";

const LeaderBoard = () => {
  const { isNav } = useShowNav();

  const { isLoading, loadMore, data, limit } = useFetchLeaderBoardPlayer();

  const topRanks = useMemo(() => {
    if (data && data.length > 0) {
      return data.slice(0, 3).map((item, index) => {
        return {
          id: item?.uniqueid,
          name: item?.username,
          level: item?.playerActiveLevel,
          current_level_points: item?.playerLevelPoints,
          currentSeasonXP: item?.currentSeasonXP,
          profileImage: item?.userDisplayImage,
          rank_badge: index === 0 ? Rank1 : index === 1 ? Rank2 : Rank3,
          sm: index === 0 ? 6 : index === 1 ? 6 : 12,
          rank: index + 1,
        };
      });
    }

    return [];
  }, [data]);

  const rankListData = useMemo(() => {
    if (data && data.length > 0) {
      return data.slice(3).map((item, index) => {
        return {
          id: item?.uniqueid,
          name: item?.username,
          level: item?.playerActiveLevel,
          current_level_points: item?.playerLevelPoints,
          profileImage: item?.userDisplayImage,
          rank: index + 4,
          currentSeasonXP: item?.currentSeasonXP,
        };
      });
    }

    return [];
  }, [data]);

  const isTablet = useViewportWidth() < 768;

  return (
    <div className="leader-board">
      <Navbar isNav={isNav} />
      <Link to="/">
        <img className="logo-img" src={logo} alt="" />
      </Link>
      <img
        src={isTablet ? BannerSeason1Mobile : BannerSeason1}
        alt="LeadBoard competition season 1 is live"
        className="w-100"
      />

      <div className="leader-board-wrapper">
        <div className="heading">
          <h1>LEADERBOARD</h1>
          <p>THE UNSUNG HEROES OF THE BATTLECRAFT WORLD!</p>
        </div>

        <Row className="mb-4 header-cards">
          <Col md={6}>
            <TotalReward />
          </Col>
          <Col md={6}>
            <CompetitionEnds />
          </Col>
        </Row>

        <Row className="top-rank-wrapper">
          {topRanks.map((rank) => (
            <Col sm={rank.sm} lg={4} key={rank.id}>
              <TopRank data={rank} />
            </Col>
          ))}
        </Row>

        <div className="mt-4">
          {rankListData.map((item) => (
            <Rank key={item?.id} data={item} />
          ))}
        </div>
      </div>
      {limit < MAX_LEADER_BOARD_COUNT && rankListData?.length > 0 && (
        <Button
          variant="outline-primary"
          className="d-block mx-auto border-white text-white bg-transparent"
          isLoading={isLoading}
          onClick={loadMore}
        >
          Load more
        </Button>
      )}

      <GetGame />
      <Footer />
    </div>
  );
};

export default LeaderBoard;
