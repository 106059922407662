import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Countdown from "react-countdown";
import close from "../images/close.png";
import logo from "../images/logo.svg";
import TopNav from "../components/topNav";
import web from "../images/comming/coming.mp4";
import mobile from "../images/comming/comingmob.mp4";
import GetGame from "../components/landing/getGame";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import GetGameLinks from "../components/common/GetGameLinks";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Commingsoon({ history }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isNav, setIsNAv] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      if (window.pageYOffset > 128) {
        setIsNAv(true);
      } else {
        setIsNAv(false);
      }
    }

    window.addEventListener("scroll", handleResize);
    return () => window.removeEventListener("scroll", handleResize);
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderVideo = () => (
    <>
      {windowDimensions.width < 767 && (
        <video
          className={` mobile-video`}
          width="100%"
          playsInline={true}
          autoPlay
          muted
          loop
        >
          <source src={mobile} type="video/mp4" />
        </video>
      )}

      {windowDimensions.width > 767 && (
        <video
          className={` web-video`}
          width="100%"
          playsinline={true}
          autoPlay
          muted
          loop
        >
          <source src={web} type="video/mp4" />
        </video>
      )}
      {/* <img src={loader} className={`${isLoad ? 'hidden': 'web-loader'} `} alt=""/> */}
    </>
  );

  const renderer = ({ days, hours, minutes, seconds }) => {
    const day = days.toString().length === 1 ? 0 : "";
    const hour = hours.toString().length === 1 ? 0 : "";
    const min = minutes.toString().length === 1 ? 0 : "";
    const sec = seconds.toString().length === 1 ? 0 : "";
    return (
      <div>
        <p className="time-para">
          <div>
            <span className="time">
              {day}
              {days}
            </span>
            <span className="days">Days</span>
          </div>
          <span className="coln">:</span>
          <div>
            <span className="time">
              {hour}
              {hours}
            </span>
            <span className="days">Hours</span>
          </div>
          <span className="coln">:</span>
          <div>
            <span className="time">
              {min}
              {minutes}
            </span>
            <span className="days">Minutes</span>
          </div>
          <span className="coln">:</span>
          <div>
            <span className="time">
              {sec}
              {seconds}
            </span>
            <span className="days">Seconds</span>
          </div>
        </p>
      </div>
    );
  };

  const tDate = new Date(Date.UTC(2022, 2, 13, 18, 0, 0));
  const utcDate = tDate.toUTCString();

  return (
    <div className="comming row no-gutters">
      <TopNav isNav={isNav} />
      <Modal
        classNames={{ modal: "download" }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <img
          onClick={() => setIsOpen(false)}
          className="close-img"
          src={close}
          alt=""
        />
        <h1>GET THE GAME</h1>
        <p>DOWNLOAD BATTLE CRAFT TO PLAY ON ANY OF THE SUPPORTED PLATFORMS.</p>
        <div className="sub-main">
          <GetGameLinks />
        </div>
      </Modal>
      <img
        onClick={() => history.push("/")}
        className="logo-img"
        src={logo}
        alt=""
      />
      <div className="col-12">
        <div className="sub-coming container">
          <div className="main-div">
            <h5>NFT MARKETPLACE</h5>
            <h1>COMING SOON</h1>
            <p>
              Stay tuned as we are chargitng up the jets for the battlecraft NFT
              Marketplace!
            </p>
            <a
              href="https://www.youtube.com/channel/UCq-uVSZ8_xIiUFQuHA_lj1A"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              WATCH TRAILER
            </a>
            <button onClick={() => setIsOpen(true)} className="btn inActive">
              GET GAME
            </button>
          </div>
          <Countdown renderer={renderer} date={utcDate} />
        </div>
        {renderVideo()}
      </div>
      <GetGame />
      <Footer />
    </div>
  );
}

export default Commingsoon;
