import { Fragment, useEffect, useState } from "react";

import { clsx } from "clsx";
import moment from "moment";
import "./countdown.css";

function Countdown({
  value,
  classNames = {
    container: "",
    label: "",
    countDownContainer: "",
  },
}) {
  const calculateTimeLeft = () => {
    const formattedDate = moment(value).format("YYYY-MM-DD HH:mm A");

    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    if (formattedDate) {
      const difference = moment.utc(formattedDate).valueOf() - moment.utc().valueOf();

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (value && value !== null) {
      setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }
  });

  const toMinimumIntegerDigits = (v) => {
    if (value === null) return "--";
    return v.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  return (
    <div className={clsx(classNames.container)}>
      <div className={clsx("countdown-container", classNames.countDownContainer)} style={{ gap: 4 }}>
        {Object.entries(timeLeft).map(([k, v]) => (
          <Fragment key={k}>
            <div className={clsx("text-center", k)}>
              <div className="digit font-bold rounded-md">{toMinimumIntegerDigits(v)}</div>
              <div className="digit-label font-medium">{k}</div>
            </div>
            <span className="colon">:</span>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default Countdown;
