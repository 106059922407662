import React from 'react';

const styles = {
  container: {
    color: 'black',
    paddingLeft: '40px',
    paddingRight: '40px',
    overflowX: 'hidden', // Corrected to disable horizontal scrolling
  },
  title: {
    color: '#66b6ee',
    textAlign: 'center',
    marginTop: '30px',
    fontSize: '2rem',
  },
  titleblue: {
    color: '#66b6ee',
    marginTop: '30px',
    fontSize: '1.7rem',
  },
  titlebluesmall: {
    color: '#66b6ee',
    marginTop: '30px',
    fontSize: '1.2rem',
  },
  titlebluesmallsmall: {
    color: '#66b6ee',
    marginTop: '30px',
    fontSize: '1rem',
  },
  paragraph: {
    textAlign: 'left',
    marginTop: '10px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginTop: '10px',
  },
  topMargin: {
    marginTop: '10px',
  },
};

export default function AppTermsAndConditions() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Battle Craft Terms of Service</h1>
     
      <h1 style={styles.titlebluesmall}>1. Acceptance of Terms</h1>
      <p>
        By downloading, installing, or using the Battle Craft Game App, you agree to abide by these
        Terms and Conditions, our Privacy Policy, and any other guidelines or rules applicable to our
        app. If you do not agree to any of these terms, you may not access or use the app.
      </p>


      <h1 style={styles.titlebluesmall}>2. License</h1>
      <p>
      Battle Craft Game is licensed, not sold, to you for use only under the terms of this license. Grey
Space OÜ reserves all rights not expressly granted to you.
      </p>


      <h1 style={styles.titlebluesmall}>3. User Accounts</h1>
      <p>
      You may need to create an account to access certain features of the app. You are responsible
for maintaining the confidentiality of your account information and are fully responsible for all
activities that occur under your account.
      </p>

      <h1 style={styles.titlebluesmall}>4. Prohibited Conduct
</h1>
      <p>
      You agree not to engage in any of the following prohibited activities while using the Battle Craft
Game App:

      </p>

      <ul>
        <li>Violating any applicable laws or regulations</li>
        <li>Interfering with or disrupting the app or servers.</li>
        <li>Using cheats, exploits, or any unauthorized third-party software.</li>
        <li>Impersonating another person or entity.</li>
      </ul>


      <h1 style={styles.titlebluesmall}>5. Intellectual Property

</h1>
      <p>
      The Battle Craft Game App, including all content, features, and functionality, is owned by Grey
Space OÜ and is protected by copyright, trademark, and other intellectual property laws.

      </p>


      <h1 style={styles.titlebluesmall}>
      6. User Content
</h1>
      <p>
    
      By using the app, you may submit or upload content, including but not limited to usernames,
avatars, and gameplay footage. You retain ownership of any intellectual property rights in the
content you submit, but you grant Grey Space OÜ a worldwide, non-exclusive, royalty-free,
sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works
of, display, and perform your user content.

      </p>


      <h1 style={styles.titlebluesmall}>
      7. Termination
</h1>
      <p>
    
      Grey Space OÜ may terminate or suspend your access to the Battle Craft Game App at any
time, without prior notice or liability, for any reason whatsoever, including without limitation if you
breach the Terms
      </p>


      <h1 style={styles.titlebluesmall}>
      8. Limitation of Liability
</h1>
      <p>
      To the fullest extent permitted by applicable law, in no event shall Grey Space OÜ, its affiliates,
or their respective officers, directors, employees, or agents be liable for any indirect, incidental,
special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred
directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.

      </p>


      <h1 style={styles.titlebluesmall}>
      9. Governing Law
</h1>
      <p>
    
      These Terms and Conditions shall be governed by and construed in accordance with the laws of
Estonia, without regard to its conflict of law provisions.
      </p>


      <h1 style={styles.titlebluesmall}>
      10. Changes to Terms
</h1>
      <p>
      Grey Space OÜ reserves the right, at its sole discretion, to modify or replace these Terms and
Conditions at any time. It is your responsibility to review these Terms periodically for changes.
Your continued use of the Battle Craft Game App after any such changes constitutes your
acceptance of the new Terms

      </p>

      <p>
      By using the Battle Craft Game, you agree to these Terms and Conditions. If you have any
questions or concerns regarding these terms, please contact us at <a href='mailto:connect@battlecraft.space'>Connect@battlecraft.space</a>.
Enjoy your gaming experience!
      </p>


      
    </div>
  );
}
