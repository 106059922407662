/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-self-assign */
import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import logo from "../images/logo.svg";
import play from "../images/landing/playicon.svg";
import oculusicon from "../images/oculusicon.svg";
import oculusDark from "../images/oculusDark.png";
import playDark from "../images/playDark.png";
import * as Scroll from "react-scroll";
import "react-responsive-modal/styles.css";
import GetTheGameModal from "./common/GetTheGameModal";
let Links = Scroll.Link;

const Navbar = ({ history, isNav }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <nav
        className="sec-nav navbar dark second-navbar
       sticky-top navbar-toggleable-md navbar-expand-lg navbar-light scrolling-navbar double-nav"
      >
        <GetTheGameModal isOpen={isOpen} toggleOpen={toggleOpen} />
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="nav left  navbar-nav ml-auto">
            <li className="nav-item ">
              <Link to="/marketplace">MARKETPLACE</Link>
            </li>
            <li className="nav-item sky" data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/skyverse">
                <img className="light-img" src={oculusicon} alt="" />
                <img className="dark-img" src={oculusDark} alt="" /> SKYVERSE
              </Link>
            </li>
            <li className="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show">
              <a href="https://battle-craft-game.gitbook.io/battlepaper/" target="_blank" rel="noreferre noreferrer">
                BATTLE PAPER
              </a>
            </li>
          </ul>
          <ul className="right nav navbar-nav ml-auto">
            <li className="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/early-access">EARLY ACCESS</Link>
            </li>
            <li className="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show">
              <Links
                onClick={() => {
                  history.push("/");
                }}
                className="scroller"
                to="audit"
                spy={false}
                smooth={true}
                offset={-130}
                duration={800}
              >
                AUDIT
              </Links>
            </li>
            <li
              className="nav-item position-relative"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <a onClick={() => setIsOpen(true)}>
                <img className="light2-img" alt="" src={play} />
                <img className="dark2-img" alt="" src={playDark} /> GET GAME
              </a>
            </li>
          </ul>
        </div>
        <Link className={`${isNav && "showit"} navbar-brand mx-auto d-block text-center order-0 order-md-1`} to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div className="navbar-collapse collapse dual-nav w-50 order-2">
          <ul className="nav web-nav navbar-nav mr-auto">
            <li className="nav-item ">
              <Link to="/early-access">EARLY ACCESS</Link>
            </li>
            <li className="nav-item ">
              <Links
                onClick={() => {
                  history.push("/");
                }}
                className="scroller"
                to="audit"
                spy={false}
                smooth={true}
                offset={-130}
                duration={800}
              >
                AUDIT
              </Links>
            </li>
            <li onClick={() => setIsOpen(true)} className="nav-item position-relative">
              <a onClick={() => setIsOpen(true)}>
                <img className="light2-img" alt="" src={play} /> <img className="dark2-img" alt="" src={playDark} /> GET
                GAME
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default withRouter(Navbar);
