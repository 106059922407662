import { Card } from "react-bootstrap";
import Countdown from "../common/countdown/Countdown";

function CompetitionEnds() {
  return (
    <Card className="primary-card">
      <Card.Body>
        <div className="text-center">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <p className="xsmall text-muted m-0">COMPETITION ENDS</p>
          </div>
          <Countdown value="2023-07-01 12:00 am" />
        </div>
      </Card.Body>
    </Card>
  );
}

export default CompetitionEnds;
