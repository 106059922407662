import React, { useState } from "react";
import circle from "../../images/audit/sale.png";
import play from "../../images/landing/ply.svg";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import close from "../../images/close.png";
import { Link } from "react-router-dom";

import GetGameLinks from "../common/GetGameLinks";

const NftHype = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="col-12 sale mt-5">
      <Modal classNames={{ modal: "download" }} open={isOpen} onClose={() => setIsOpen(false)}>
        <img onClick={() => setIsOpen(false)} className="close-img" src={close} alt="" />
        <h1>GET THE GAME</h1>
        <p>DOWNLOAD BATTLE CRAFT TO PLAY ON ANY OF THE SUPPORTED PLATFORMS.</p>
        <GetGameLinks />
      </Modal>
      <div className="row no-gutters main">
        <img alt="" className="col-md-7 circle  order-md-1 order-sm-12 order-12" src={circle} />
        <div className="col-md-5 sub-hype order-md-12 order-sm-1 order-1">
          <h2>GET IT BEFORE YOU MISS</h2>
          <h1>EARLY BIRD DISCOUNT</h1>
          <p>
            What a golden opportunity!! Now try getting into the early access o NFTs and earn the chance to get selected
            in the Whitelisted winners.The early whitelisted winners will be getting 3 voucher codes with each NFT they
            win in Whitelisting.{" "}
          </p>
          <p>THE DEAL IS WORTH GETTING YOUR HANDS ON! GET YOUR DISCOUNTS NOW</p>
          {/* <a href='https://www.youtube.com/channel/UCq-uVSZ8_xIiUFQuHA_lj1A' target="_blank"  className='btn'>GO TO MARKETPLACE</a> */}
          <Link to="/marketplace" className="btn">
            GO TO MARKETPLACE
          </Link>
          <button onClick={() => setIsOpen(true)} className="btn active-btn">
            <img alt="" src={play} />
            GET A REFERAL CODE
          </button>
        </div>
      </div>
    </div>
  );
};

export default NftHype;
