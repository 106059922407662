import React from "react";
import XPSymbol from "../../images/leaderboard/xp-icon.svg";
import XPCoinIcon from "../../images/leaderboard/xp-coin.svg";

function XPCoins({ value = 0 }) {
  return (
    <div className="xp">
      <img src={XPCoinIcon} alt="" />
      <span>{value.toLocaleString()}</span>
      <img src={XPSymbol} alt="" />
    </div>
  );
}

export default XPCoins;
