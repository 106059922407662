import React from "react";
import cross from "../../images/landing/cross.png";
import diving from "../../images/landing/diving.png";
import lineone from "../../images/landing/line1.svg";
import linemob from "../../images/landing/line1mob.svg";
import playBtn from "../../images/landing/playBtn.png";
import pauseBtn from "../../images/landing/pauseBtn.png";
import trailerOne from "../../images/landing/trailerOne.mp4";
import trailerTwo from "../../images/landing/trailerTwo.mp4";
import funsol from "../../images/landing/funsol.svg";
import fiveM from "../../images/landing/500M.png";
import mute from "../../images/landing/mute.png";
import unmute from "../../images/landing/unmute.png";

class Game extends React.Component {
  state = {
    pause: true,
    pauseTwo: true,
    showImage: true,
    showImageTwo: true,
    mutedTwo: false,
    muted: false,
  };

  playVideo = () => {
    console.log("play");
    this.setState({ showImageTwo: false });
    // You can use the play method as normal on your video ref
    this.refs.vidRef.play();
    this.setState({ pause: !this.state.pause });
  };

  pauseVideo = () => {
    console.log("pause");
    // Pause as well
    this.refs.vidRef.pause();
    this.setState({ pause: !this.state.pause });
  };

  playVideoTwo = () => {
    console.log("play");
    this.setState({ showImage: false });
    // You can use the play method as normal on your video ref
    this.refs.vidRefTwo.play();
    this.setState({ pauseTwo: !this.state.pauseTwo });
  };

  pauseVideoTwo = () => {
    console.log("pause");
    // Pause as well
    this.refs.vidRefTwo.pause();
    this.setState({ pauseTwo: !this.state.pauseTwo });
  };
  toggleMuteOne = () => {
    this.setState({ muted: !this.state.muted });
    this.refs.vidRef.muted = this.state.muted;
    console.log(this.state.muted);
  };
  toggleMuteTwo = () => {
    this.setState({ mutedTwo: !this.state.mutedTwo });
    this.refs.vidRefTwo.muted = this.state.mutedTwo;
    console.log(this.state.mutedTwo);
  };
  render() {
    return (
      <>
        <div className="col-12 game">
          <img width="100%" className="last-img hide" src={lineone} alt="" />
          <img className="mob-last-img" src={linemob} alt="" />
          <div className="sub-games">
            <h1 className="heading mb-4">JOINT VENTURE AND POWERED BY:</h1>
            <div className="images-divv">
              <a href="https://divingstudio.com/" className="link" target="_blank" rel="noreferrer">
                {" "}
                <img className="mt-2" src={diving} alt="" />
              </a>
              <img className="cross" src={cross} alt="" />
              <a href="https://funsoltech.com/" className="link" target="_blank" rel="noreferrer">
                {" "}
                <img src={funsol} alt="" className="logo" />{" "}
              </a>
              <div class="vertical"></div>
              <img alt="" src={fiveM} />
            </div>
          </div>
          <div className="row no-gutters ">
            <div className="col-md-6 videoContainer">
              <video width="100%" ref="vidRef" playsInline={true} autoPlay={false} muted={this.state.muted}>
                <source src={trailerTwo} type="video/mp4" />
              </video>
              {this.state.muted ? (
                <img alt="" src={mute} onClick={this.toggleMuteOne} className="mute" />
              ) : (
                <img alt="" src={unmute} onClick={this.toggleMuteOne} className="mute" />
              )}
              {this.state.pause ? (
                <img
                  src={playBtn}
                  alt=""
                  className="position-absolute d-block"
                  onClick={() => {
                    this.playVideo();
                    if (!this.state.pauseTwo) {
                      this.pauseVideoTwo();
                    }
                  }}
                />
              ) : (
                <img src={pauseBtn} alt="" className="position-absolute" onClick={this.pauseVideo} />
              )}
            </div>
            <div className="col-md-6 videoContainer">
              <video width="100%" ref="vidRefTwo" playsInline={true} autoPlay={false} muted={this.state.mutedTwo}>
                <source src={trailerOne} type="video/mp4" />
              </video>
              {this.state.mutedTwo ? (
                <img alt="" src={mute} onClick={this.toggleMuteTwo} className="mute" />
              ) : (
                <img alt="" src={unmute} onClick={this.toggleMuteTwo} className="mute" />
              )}
              {this.state.pauseTwo ? (
                <img
                  src={playBtn}
                  alt=""
                  className="position-absolute d-block"
                  onClick={() => {
                    this.playVideoTwo();
                    if (!this.state.pause) {
                      this.pauseVideo();
                    }
                  }}
                />
              ) : (
                <img src={pauseBtn} alt="" className="position-absolute" onClick={this.pauseVideoTwo} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Game;
