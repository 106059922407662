import React from "react";
import GrabNft from "../components/audit/GrabNft";
import Footer from "../components/footer";
import GetGame from "../components/landing/getGame";
import logo from "../images/logo.svg";

import Navbar from "../components/topNav";
import NftHype from "../components/audit/NftHype";
import Program from "../components/audit/Program";
import useShowNav from "../hook/useShowNav";

const Audit = ({ history }) => {
  const { isNav } = useShowNav();

  return (
    <div className="row no-gutters audit">
      <Navbar isNav={isNav} />
      <img onClick={() => history.push("/")} className="logo-img" src={logo} alt="" />
      <GrabNft />
      <Program />
      <NftHype />
      <GetGame />
      <Footer />
    </div>
  );
};

export default Audit;
