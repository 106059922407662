import React from 'react'
import battleRoyale from '../../images/landing/royaleBattle.svg';

const Trailer = () => {
  return (
    <div className='col-12 trailer'>
      <div className='row no-gutters'>
        <div className='col-md-6'>
          <img src={battleRoyale} alt="" />
          <p className='mt-4'>INDULGE IN A 1 VS 10 BATTLE TO SURVIVE THE FURY OF THE GALAXY WARS!</p>
        </div>
      </div>
    </div>
  )
}

export default Trailer