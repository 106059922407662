import React from "react";
import deathMatch from "../../images/landing/deathMatch.svg";
import freePlay from "../../images/landing/freePlay.svg";

const DeathMatch = () => {
  return (
    <div className="col-12 death">
      <div className="row no-gutters">
        <div className="col-md-8 deathMatch">
          <div className="absMobile">
            <img alt="" src={deathMatch} />
            <p>CHALLENGE YOUR FRIENDS OR COMPETITIVE PLAYERS ALL AROUND THE WORLD TO WIN EXCITING REWARDS.</p>
          </div>
        </div>
        <div className="col-md-4 freePlay">
          <div className="absMobile">
            <img alt="" className="free-img" src={freePlay} />
            <p>GEAR UP WITH PRACTICE MODE TO PREPARE YOURSELF FOR COMPETITIVE BATTLES TO SURVIVE & WIN.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeathMatch;
