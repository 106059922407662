import React from "react";

import { clsx } from "clsx";

import Loader from "../loader/Loader";
import { Button as BSButton } from "react-bootstrap";

function Button({
  isLoading = false,
  children = null,
  className = "",
  onClick = () => {},
  disabled = false,
  ...rest
}) {
  const handleClick = () => {
    if (isLoading) return;
    onClick();
  };

  return (
    <BSButton
      disabled={disabled}
      className={clsx(className)}
      onClick={handleClick}
      {...rest}
    >
      {isLoading ? <Loader show /> : children}
    </BSButton>
  );
}

export default Button;
