import { Button, Card, Image, OverlayTrigger, Popover } from "react-bootstrap";
import IndicatorIcon from "../../images/leaderboard/indicator-icon.svg";
import { getNumberSuffix } from "../../utils/getNumberSuffix";
import { LEADER_BOARD_REWARD } from "../../constants/global";
import useViewportWidth from "../../hook/useViewport";

function TotalReward() {
  const isTablet = useViewportWidth() < 992;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="popover-container">
        <h3 className="xsmall fw-500">REWARD BREAK DOWN</h3>

        <ul>
          {LEADER_BOARD_REWARD.map(({ id, place, reward }) => (
            <li key={id}>
              <p>
                {place} <sup>{getNumberSuffix(place)}</sup> Place
              </p>
              <p>{reward} USDT</p>
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <Card className="primary-card">
      <Card.Body>
        <div className="text-center">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <p className="xsmall text-muted m-0">TOTAL REWARD</p>

            <OverlayTrigger placement={isTablet ? "auto" : "right-start"} trigger="hover" overlay={popover}>
              {({ ref, ...triggerHandler }) => (
                <Button variant="text" {...triggerHandler} className="d-inline-flex align-items-center">
                  <Image ref={ref} src={IndicatorIcon} />
                </Button>
              )}
            </OverlayTrigger>
          </div>
          <p className="h2 fw-600">200 USDT</p>
        </div>
      </Card.Body>
    </Card>
  );
}

export default TotalReward;
