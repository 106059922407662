import moment from "moment";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { AUTH_KEY } from "../constants/global";

function ProtectedRoute({ path, key, children }) {
  const date = localStorage.getItem(AUTH_KEY);

  const isAuthenticated = Boolean(date && date > moment.utc().valueOf());
  // const isAuthenticated = true;
  // console.log(children, 'child')

  if (!isAuthenticated) return <Redirect to="/password-protected" />;
  return (
    <Route
      key={key}
      path={path}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/password-protected",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
