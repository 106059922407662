export const APPLE_STORE_URL = "https://apps.apple.com/us/app/battlecraft/id6444357551";

export const GOOGLE_STORE_URL = "https://play.google.com/store/apps/details?id=com.BattleCraft.Space&pli=1";

export const DESKTOP_APP_DOWNLOAD_URL =
  "https://drive.google.com/uc?export=download&id=1gHEyu4OOk6E1gWlAR0qsdyJu7dZnGUt4";

export const LEADER_BOARD_REWARD = [
  {
    id: 1,
    reward: 70,
    place: 1,
  },
  {
    id: 2,
    reward: 40,
    place: 2,
  },
  {
    id: 3,
    reward: 30,
    place: 3,
  },
  {
    id: 4,
    reward: 10,
    place: 4,
  },
  {
    id: 5,
    reward: 10,
    place: 5,
  },
  {
    id: 6,
    reward: 10,
    place: 6,
  },
  {
    id: 7,
    reward: 10,
    place: 7,
  },
  {
    id: 8,
    reward: 10,
    place: 8,
  },
  {
    id: 9,
    reward: 5,
    place: 9,
  },
  {
    id: 10,
    reward: 5,
    place: 10,
  },
];
export const API_BASE_URL = "https://api.battlecraft.space";

export const MAX_LEADER_BOARD_COUNT = 91;

export const AUTH_KEY = "b94cd99b6918c1892d9c168e8e21a6fc";
