import React from "react";
import telegram from "../../images/landing/telegrams.png";
import discord from "../../images/landing/discords.png";
import twitter from "../../images/landing/twitters.png";

const Discord = () => {
  return (
    <div className="col-12">
      <div className="container discords">
        <h1 className="mt-5">JOIN THE COMMUNITY</h1>
        <div className="row no-gutters justify-content-center">
          <div className="col-md-5 twitter">
            <div className="d-flex justify-content-around align-items-center">
              <img alt="" src={twitter} />
              <div>
                <a href="https://twitter.com/BattleCraftNFT" target="_blank" className="btn playBtn" rel="noreferrer">
                  FOLLOW TWITTER
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5 telegram">
            <div className="d-flex justify-content-around align-items-center">
              <img alt="" src={telegram} />
              <div>
                <a href="https://t.me/BattleCraftAnn" target="_blank" className="btn playBtn" rel="noreferrer">
                  JOIN CHANNEL
                </a>
                <a href="https://t.me/BattleCraftGroup" target="_blank" className="btn playBtn mt-3" rel="noreferrer">
                  JOIN GROUP
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5 discord">
            <div className="d-flex justify-content-around align-items-center">
              <img alt="" src={discord} />
              <div>
                <a
                  href="https://discord.com/invite/ekgvensjb6"
                  target="_blank"
                  className="btn playBtn"
                  rel="noreferrer"
                >
                  JOIN SERVER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discord;
