import { Link } from "react-router-dom";

const LeaderBoardBanner = () => {
  return (
    <Link to="/leader-board">
      <div className="leader-board-banner">
        <div class="pulse-circle pulse mb-md-3 mx-3 mx-md-0">
          <div className="inner-circle" />
        </div>
        <div>LIVE COMPETITION | Leaderboard</div>
      </div>
    </Link>
  );
};

export default LeaderBoardBanner;
