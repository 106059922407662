import React from "react";
import { Link } from "react-router-dom";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../../constants/global";
import apkt from "../../images/landing/APKT.png";
import googleplay from "../../images/landing/googleplay.png";
import apple from "../../images/landing/apple.png";
import desktop from "../../images/landing/desktop-icon.png";

function GetGameLinks() {
  return (
    <div className="get-game-wrapper">
      <a href={GOOGLE_STORE_URL} target="_blank" rel="noreferre noreferrer">
        <img contextMenu="" src={googleplay} alt="battle craft game in google store" />
      </a>
      <a target="_blank" rel="noreferre noreferrer" href={APPLE_STORE_URL}>
        <img src={apple} alt="battle craft game in apple store" />
      </a>
      <Link to="#">
        <img src={desktop} alt="" />
      </Link>

      <a href="apk/BattleCraft.apk" target="_blank">
        <img src={apkt} alt="" />
      </a>
    </div>
  );
}

export default GetGameLinks;
