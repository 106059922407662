import React from "react";
import { Link } from "react-router-dom";
import guardians from "../../images/landing/guardians.svg";

const Guardians = () => {
  return (
    <div className="col-12 guardians">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-md-6">
            <img src={guardians} alt="" className="img-fluid" />
          </div>
          <div className="col-md-6 partners">
            <h1>GET EARLY ACCESS</h1>
            <Link to="/early-access">
              <button className="btn playBtn ml-0">EARLY ACCESS</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guardians;
