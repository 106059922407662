import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import girl from "../../images/landing/girl.png";

const RoadMap = () => {
  const array = [
    {
      phase: "PHASE I",
      heading: "PROJECT FEASIBILITY",
      heading2: "TEAM BUILDING",
      heading3: "NFT ART CONCEPTUALIZATION",
      heading4: "WEBSITE DEVELOPMENT",
      heading5: "GAME DEVELOPMENT - INITIAL PHASE",
    },
    {
      phase: "PHASE II",
      heading: "WEBSITE LAUNCH",
      heading2: " BATTLE PAPER & PITCH DECK",
      heading3: "RELEASE",
      heading4: "STRATEGIC PARTNERSHIPS GLOBAL MARKETING CAMPAIGN",
    },
    {
      phase: "PHASE III",
      heading: "SEED AND STRATEGIC SALE ROUNDS",
      heading2: "GAMEPLAY RELEASE",
      heading3: "FREE TO PLAY MODE RELEASE",
      heading4: "MARKETING & PROMOTION",
      heading5: "NFT AIRDROP",
      heading6: "NFT SALE ROUND",
    },
  ];

  const array2 = [
    {
      phase: "PHASE IV",
      heading: "NFT MARKETPLACE LAUNCH",
      heading2: "$CHRG IGO & LISTING",
      heading3: "PARTNERSHIP",
      heading4: "ANNOUNCEMENTS",
      heading5: "INITIAL GAME TESTING",
    },
    {
      phase: "PHASE V",
      heading: "STRATEGIC PARTNERSHIPS",
      heading2: "P2E PVP MODE ALPHA RELEASE",
      heading3: "BATTLE ROYALE ALPHA RELEASE",
      heading4: "GAME PROMOTION",
    },
    { phase: "PHASE VI", heading: "TOURNAMENTS LAUNCH", heading2: "MORE GAME UPDATES", heading3: "PARTNERSHIPS" },
  ];

  const settings = {
    arrows: false,
    infinite: true,
    speed: 480,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: true,
    dots: true,
  };
  return (
    <>
      <div className="col-12 roadmap">
        <div className="main-road">
          <img src={girl} alt="" />
          <div>
            <h1 className="heading">ROADMAP</h1>
            <div className="sub-road">
              {array.map((arr, i) => (
                <div>
                  <h2>{arr.phase}</h2>
                  <ul>
                    <li>{arr.heading}</li>
                    <li>{arr.heading2}</li>
                    <li>{arr.heading3}</li>
                    {arr.heading4 && <li>{arr?.heading4}</li>}
                    {arr.heading5 && <li>{arr?.heading5}</li>}
                    {arr.heading6 && <li>{arr?.heading6}</li>}
                  </ul>
                </div>
              ))}
            </div>
            <div className="sub-road sec-road">
              {array2.map((arr, i) => (
                <div>
                  <h2>{arr.phase}</h2>
                  <ul>
                    <li>{arr.heading}</li>
                    <li>{arr.heading2}</li>
                    <li>{arr.heading3}</li>
                    {arr.heading4 && <li>{arr?.heading4}</li>}
                    {arr.heading5 && <li>{arr?.heading5}</li>}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="road-mob">
          <h1 className="heading">ROADMAP</h1>
          <Slider {...settings}>
            <div className="sub-road">
              {array.map((arr, i) => (
                <div>
                  <h2>{arr.phase}</h2>
                  <span>{arr.heading}</span>
                  <span>{arr.heading2}</span>
                  <span>{arr.heading3}</span>
                  {arr.heading4 && <span>{arr?.heading4}</span>}
                  {arr.heading5 && <span>{arr?.heading5}</span>}
                  {arr.heading6 && <span>{arr?.heading6}</span>}
                </div>
              ))}
            </div>
            <div className="sub-road sec-road">
              {array2.map((arr, i) => (
                <div>
                  <h2>{arr.phase}</h2>
                  <span>{arr.heading}</span>
                  <span>{arr.heading2}</span>
                  <span>{arr.heading3}</span>
                  {arr.heading4 && <span>{arr?.heading4}</span>}
                  {arr.heading5 && <span>{arr?.heading5}</span>}
                </div>
              ))}
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RoadMap;
