import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Navbar from "../components/topNav";
import logo from "../images/logo.svg";
import oculus from "../images/landing/three-dee/oculus.svg";
import monster from "../images/skyverse/monster.png";
import cardboard from "../images/landing/three-dee/cardboard.svg";
import oculusLogo from "../images/landing/three-dee/oculus-logo.svg";
import valve from "../images/landing/valve.svg";
import vivie from "../images/landing/vive.svg";
import webxr from "../images/landing/webxr.svg";

function Skyverse({ history }) {
  const [isNav, setIsNAv] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.pageYOffset > 128) {
        setIsNAv(true);
      } else {
        setIsNAv(false);
      }
    }

    window.addEventListener("scroll", handleResize);
    return () => window.removeEventListener("scroll", handleResize);
  }, []);
  return (
    <div className="skyverse row no-gutters">
      <Navbar isNav={isNav} />
      <img
        onClick={() => history.push("/")}
        className="logo-img"
        src={logo}
        alt=""
      />
      <div className="col-12">
        <div className="main">
          <img className="mob-img" src={monster} alt="" />
          <div className="sub-main">
            <h2>EXPERIENCE THE</h2>
            <h1>SKYVERSE REALITY</h1>
            <p>
              LETS'S STEPINTO THE NEW DIMENSION OF BATTLECRAFT SKYVERSE, A
              MEGICAL EXPERIENCE FULL OF VR ADVENTURE. USE YOUR BR HEADSET TO
              EXPERIENCE THE BEST OF IT ALL IN 360
            </p>
            <span>JUST STRAP IT ON LIVE ION ADVENTURE</span>
            <a
              href="https://sky.battlecraft.space"
              target="_blank"
              rel="noreferre noreferrer"
            >
              <button className="btn">DIVE INTO SKYVERSE</button>
            </a>

            {/* <p>HOW TO USE VR:</p>
          <span><img src={six} /> Login to <a target="_blank" href='https://www.battlecrfat.s[ace/VR'> https://www.battlecrfat.s[ace/VR</a></span>
          <span><img src={cardvoad} /> Use Oculus, Vive or a similar headset.</span>
          <span><img src={person} /> Look around to experience the Skyverse.</span> */}
          </div>
          <img className="circle" src={monster} alt="" />
        </div>
        <div className="img-div">
          <img src={oculusLogo} alt="" />
          <img src={cardboard} alt="" className="mr-3" />
          <img src={vivie} alt="" />
          <img src={valve} alt="" />
          <img src={webxr} alt="" />
        </div>
        <img className="oculus" src={oculus} alt="" />
      </div>
      <Footer />
    </div>
  );
}

export default Skyverse;
