import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";

import Navbar from "../components/topNav";
import Footer from "../components/footer";

import axios from "axios";
import loading from "../images/loader.gif";

const NFT = ({ match }) => {
  const [inventory, setInventory] = useState(null);
  const [loader, setLoader] = useState(false);

  const getInventory = async () => {
    const id = match.params.id;
    setLoader(true);
    try {
      const response = await axios.get(`/api/app/inventory/list/${id}`);
      setInventory(response.data.inventory);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getInventory();
  });

  return (
    <>
      <div className="row no-gutters fixemd-img"></div>
      <div className="row no-gutters justify-content-center NFT">
        <Navbar />
        <div className="col-lg-11 col-xl-10 main">
          <h1>Nft page </h1>

          {!inventory && loader && <img width="100%" src={loading} alt="" />}
          {!!inventory && (
            <div className="detail">
              <video width="100%" playsInline={true} autoPlay muted loop>
                <source
                  src={`${process.env.REACT_APP_API_URL}/${inventory.modelUrl3d}`}
                  type="video/mp4"
                />
              </video>
              <div className="description-div">
                <div>
                  <h2>{inventory.name}</h2>
                  <p className="desc">{inventory.description}</p>

                  <span className="heading-span">STATISTICS</span>
                  <hr />
                  <div className="progress-div">
                    <div className="sub-div">
                      <span>THRUST</span>
                      <div className="sub-progress">
                        <ProgressBar
                          animated
                          now={inventory.attributes.thrust}
                        />
                        <span>{inventory.attributes.thrust / 10}</span>
                      </div>
                    </div>
                    <div className="sub-div">
                      <span>BRAKES</span>
                      <div className="sub-progress">
                        <ProgressBar
                          animated
                          now={inventory.attributes.brakes}
                        />
                        <span>{inventory.attributes.brakes / 10}</span>
                      </div>
                    </div>
                    <div className="sub-div">
                      <span>HANDLING</span>
                      <div className="sub-progress">
                        <ProgressBar
                          animated
                          now={inventory.attributes.handling}
                        />
                        <span>{inventory.attributes.handling / 10}</span>
                      </div>
                    </div>
                    <div className="sub-div">
                      <span>SPEED</span>
                      <div className="sub-progress">
                        <ProgressBar
                          animated
                          now={inventory.attributes.speed}
                        />
                        <span>{inventory.attributes.speed / 10}</span>
                      </div>
                    </div>
                  </div>

                  <span className="heading-span">NFT PRICE & DETAILS</span>
                  <hr />
                  <div className="price-div">
                    <div>
                      <p>
                        {inventory.quantity} <span>left of</span>{" "}
                        {inventory.totalSupply}
                      </p>
                      <span>AVAILBILITY</span>
                    </div>
                    <div>
                      <h3>
                        ${inventory.price} <span>= $JET 300</span>
                      </h3>
                      <span>PRICE</span>
                    </div>
                    <div>
                      <h3>${inventory.price}</h3>
                      <span>FLOOR</span>
                    </div>
                    <div>
                      <p>3</p>
                      <span>WALLET CAP</span>
                    </div>
                  </div>
                </div>
                <button className="btn">BUY NOW</button>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NFT;
