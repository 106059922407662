import React, { useState } from "react";

import Navbar from "../components/topNav";
import Footer from "../components/footer";
import { Scrollbars } from "react-custom-scrollbars-2";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import funcky from "../images/landing/funcky.svg";

import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import loading from "../images/loader.gif";
import search from "../images/marketplace/search.svg";
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const MarketPlace = ({ history, match }) => {
  const [inventory] = useState([
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
    {
      name: "CRAFT NAME",
      image: funcky,
      price: 44,
      quantoty: 100,
      available: "IN STOCK",
    },
  ]);
  const [loader] = useState(false);
  const [category, setCategory] = useState("");

  return (
    <>
      <div className="row no-gutters fixed -imge"></div>
      <div className="row no-gutters justify-content-center marketPlace">
        <Navbar />
        <div className="col-lg-12 main">
          <div className="row no-gutters">
            <div className="col-lg-4 col-xl-3">
              <div className="search-div">
                <span>FIND YOUR CRAFT</span>
                <div className="input-div">
                  <input
                    placeholder="Enter Keyword"
                    type="text"
                    className="form-control"
                  />
                  <img src={search} alt="" />
                </div>
              </div>
              <div className="filter-div">
                <div className="type">
                  <p>TYPE</p>
                  <span>Reset</span>
                </div>
                <div className="sub-filter">
                  <label>
                    <Checkbox
                      checked={category === ""}
                      onChange={() => setCategory("")}
                    />
                    &nbsp; ALL
                  </label>
                  <label>
                    <Checkbox
                      checked={category === "MONO_PLANE"}
                      onChange={() => setCategory("MONO_PLANE")}
                    />
                    &nbsp; FIGHTER CRAFT
                  </label>
                  <label>
                    <Checkbox
                      checked={category === "FIGHTER_JET"}
                      onChange={() => setCategory("FIGHTER_JET")}
                    />
                    &nbsp; FIGHTER CRAFT
                  </label>
                  <label>
                    <Checkbox
                      checked={category === "PRIVATE_JET"}
                      onChange={() => setCategory("PRIVATE_JET")}
                    />
                    &nbsp;FIGHTER CRAFT
                  </label>
                </div>
              </div>
              <div className="price-div">
                <div className="type">
                  <p>Price</p>
                  <span>Reset</span>
                </div>
                <Range key={1} min={0} max={10} defaultValue={[0, 10]} />
              </div>
              <div className="attribute-div">
                <div className="type">
                  <p>ATTRIBUTES</p>
                  <span>Reset</span>
                </div>
                <div className="sub-attribute">
                  <span>SPEED</span>
                  <Range key={1} min={0} max={10} defaultValue={[0, 10]} />
                </div>
                <div className="sub-attribute">
                  <span>THRUST</span>
                  <Range key={1} min={0} max={10} defaultValue={[0, 10]} />
                </div>
                <div className="sub-attribute">
                  <span>BRAKES</span>
                  <Range key={1} min={0} max={10} defaultValue={[0, 10]} />
                </div>
                <div className="sub-attribute">
                  <span>HANDLING</span>
                  <Range key={1} min={0} max={10} defaultValue={[0, 10]} />
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-xl-9">
              <Scrollbars
                style={{ height: 580, width: "100%" }}
                className="scroll-right"
                renderTrackHorizontal={(props) => (
                  <div {...props} className="track-horizontal" />
                )}
                renderTrackVertical={(props) => (
                  <div {...props} className="track-vertical" />
                )}
              >
                <div className="row sub-row no-gutters">
                  {loader && <img width="100%" src={loading} alt="" />}
                  {!loader &&
                    inventory.map((inv) => (
                      <div
                        onClick={() => history.push(`/nft/1`)}
                        className="col-lg-6 col-xl-4 col-sm-6"
                      >
                        <div className="box">
                          <img src={inv.image} alt="" />

                          <h5 className="heading">{inv.name}</h5>
                          <span className="line"></span>
                          <div className="bottom-div">
                            <div>
                              <span>{inv.price}</span>
                              <p>PRICE</p>
                            </div>
                            <div>
                              <span>{inv.quantoty}</span>
                              <p>TOTAL MINTED</p>
                            </div>
                            <div>
                              <span>IN STOCK</span>
                              <p>AVAILBILITY</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MarketPlace;
