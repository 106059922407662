import React, { useRef, useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import right from "../../images/landing/rightArrow.png";
import left from "../../images/landing/leftArrow.png";
import marcus from "../../images/landing/marcus.svg";
import hunter from "../../images/landing/hunter.png";
import tasha from "../../images/landing/tasha.png";
import ethan from "../../images/landing/ethan.png";
import lucus from "../../images/landing/lucus.png";

const NFT = () => {
  let array = [
    {
      image: marcus,
      name: "MARCUS",
      desc: "MARCUS, AN INTELLIGENT AND FOODY GUARDIAN WHO MAKES NO MISTAKE WHEN IT COMES TO ELIMINATING ENEMIES.",
    },
    { image: tasha, name: "TASHA", desc: "The woman with a fearless spirit and a heart of a warrior." },
    { image: hunter, name: "HUNTER", desc: "The true example of the guardians divinity." },
    { image: lucus, name: "LUCUS", desc: "Silent, Smart, and Sweet enough to knockout every opponent." },
    { image: ethan, name: "ETHAN", desc: "Flex in with the true asset of Galactic CHIEF-IN-COMMANDER." },
  ];

  const slideRef = useRef();
  const [id] = useState(0);
  const [value, setvalue] = useState(true);

  useEffect(() => {
    setvalue(true);
  }, [value]);

  const settings = {
    nextArrow: <img className="right-arrow" alt="" src={right} />,
    prevArrow: <img className="left-arrow" alt="" src={left} />,
    infinite: true,
    speed: 480,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  console.log(id);
  return (
    <>
      <div className="col-12">
        <div className="container nft">
          <Slider ref={slideRef} {...settings}>
            {array.map((ar) => (
              <div className="row d-flex no-gutters">
                <div className="col-md-5">
                  <img src={ar.image} alt="" className="img-fluid" />
                </div>
                <div className="text col-md-7">
                  <h1 className="heading">{ar.name}</h1>
                  <p>{ar.desc}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default NFT;
