import React from "react";
import getGame from "../../images/landing/getGame.svg";
import GetGameLinks from "../common/GetGameLinks";

const GetGame = () => {
  return (
    <div className="col-12 getGame">
      <div className="row no-gutters align-items-center">
        <div className="col-md-4 text">
          <h1>GET THE GAME</h1>
          <p>
            DOWNLOAD BATTLE CRAFT TO PLAY ON ANY OF THE SUPPORTED PLATFORMS.
          </p>
          <GetGameLinks />
        </div>
        <div className="col-md-8">
          <img src={getGame} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default GetGame;
