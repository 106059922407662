import React, { useState, useEffect } from "react";
// import ReactPlayer from 'react-player';
// import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import apple from "../../images/landing/apple.png";
import desktop from "../../images/landing/desktop-icon.png";
import logo from "../../images/logo.svg";
import play from "../../images/landing/ply.svg";
import web from "../../images/landing/web.mp4";
import mobile from "../../images/landing/Mobile.mp4";
import Laptop from "../../images/landing/Laptop.mp4";
// import apkt from "../../images/landing/APKT.png";
import googleplay from "../../images/landing/googleplay.png";
// import apkDownload from "../../apk/BattleCraft.apk";
// import copy from "../../images/landing/copy.svg";
import bnb from "../../images/landing/bnb.svg";
import polygon from "../../images/landing/polygon.svg";
import every from "../../images/landing/every.svg";
import arrow from "../../images/arrow.gif";
// import ApkDownloadButton from "../common/ApkDownloadButton";
import apkt from "../../images/landing/APKT.png";

import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "../../constants/global";
import { Link } from "react-router-dom";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const Banner = ({ setIsOpen }) => {
  const [isLoad, setisLoad] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderVideo = () => (
    <>
      {windowDimensions.width < 767 && (
        <video
          onLoadedData={() => {
            setisLoad(true);
          }}
          className={`${!isLoad && "hidden"} mobile-video`}
          width="100%"
          playsInline={true}
          autoPlay
          muted
          loop
        >
          <source src={mobile} type="video/mp4" />
        </video>
      )}
      {/* 
          {/* <ReactPlayer
           onStart={()=> setisLoad(true)}
          playsinline={true}  muted playing loop className={`${!isLoad && 'hidd'} mob`} url={mobile} /> 
       <img src={mobLoader} className={`${isLoad ? 'hidden': 'mob-loader'} `} alt=""/>

           <video onLoadedData={()=> {
          setisLoad(true)
      }}  className={`${!isLoad && 'hidden'} laptop`} width="100%" playsInline={true}  autoPlay muted loop>
           <source src={laptop} type='video/mp4'/>
         </video>
       <img src={loader} className={`${isLoad ? 'hidden': 'laptop-loader'}`} alt=""/> */}

      {windowDimensions.width > 767 && windowDimensions.width < 1201 && (
        <video
          onLoadedData={() => {
            setisLoad(true);
          }}
          className={`${!isLoad && "hidden"} web-video`}
          width="100%"
          playsinline={true}
          autoPlay
          muted
          loop
        >
          <source src={Laptop} type="video/mp4" />
        </video>
      )}
      {windowDimensions.width > 1200 && (
        <video
          onLoadedData={() => {
            setisLoad(true);
          }}
          className={`${!isLoad && "hidden"} web-video`}
          width="100%"
          playsinline={true}
          autoPlay
          muted
          loop
        >
          <source src={web} type="video/mp4" />
        </video>
      )}
      {/* <img src={loader} className={`${isLoad ? 'hidden': 'web-loader'} `} alt=""/> */}
    </>
  );

  return (
    <>
      <div className="col-12 banner">
        {renderVideo()}

        {/* <Slide transitionDuration={ 480 } duration={10000} autoPlay={false} infinite={true}
          nextArrow={<div className="right"></div>}
          indicators={true} canSwipe={false}>
            <div className="each-slide">
              {renderVideo()}
              <div  className={`${!isLoad && 'hidden'} main`}>
              <img className='stars' src={stars} alt="" />
              <h4>FLY BEYOND THE METAVERSE</h4>
              <h1>SKY IS THE LIMIT</h1>
              <p className='desc'>Jet Sports, a revolution towards a metaverse where you could fly
               your own jet high in the sky to compete, win, and earn because Sky is the limit.</p>
              <button className='btn active-btn'><img src={play} alt=""/> WATCH TEASER</button>
              {/* <button className='btn'>MARKETPLACE</button> 
            </div>
            </div>
            <div className="each-slide">
              <img className={`${!isLoad && 'hidden'} web`} src={banner} alt=""/>
              <img className={`${!isLoad && 'hidden'} mobile-video`} src={mobbanner} alt=""/>
              <img className={`${!isLoad && 'hidden'} laptop`} src={laptopBanner} alt=""/>
              <div className='main'>
              <img className='stars fans' src={fans} alt="" />
              <h4>THE HOTTEST NFT</h4>
              <h1>MARKETPLACE</h1>
              <p className='desc'>Jet Sports, a revolution towards a metaverse where you could fly
               your own jet high in the sky to compete, win, and earn because Sky is the limit.</p>
              <button className='btn active-btn'>REGISTER INTEREST</button>
            </div>
            </div>
          </Slide> */}
        <div className="social-info">
          {/* <div className={`${!isLoad && 'hidden'}  hide mob-div left-div`}>
              <div className='first-div'>
                <div className='main-address-div '>
                  <span className='address'> 
                  </span>
                </div>
                 <span className='line'></span>
                <div className='social'>
                <a href='https://twitter.com/BattleCraftNFT' target="_blank" rel="noreferre noreferrer"><img src={twitter} alt=""/></a>
                <a href='https://battlecraft.medium.com' target="_blank" rel="noreferre noreferrer"><img src={medium} alt=""/></a>
                <a href='https://t.me/BattleCraftGroup' target="_blank" rel="noreferre noreferrer"><img src={telegram} alt=""/></a>
                <a href='https://discord.com/invite/ekgvensjb6' target="_blank" rel="noreferre noreferrer"><img src={game} alt="" /></a>
                </div>
              </div>
            </div> */}
          <img className="logo-img" src={logo} alt="" />
          <div className={`${!isLoad && "hidden"} main`}>
            <h1>FIRST AI BATTLE GAME</h1>
            <p className="desc">Build Your Space Strategy with AI To Win & Earn.</p>
            <a
              href="https://www.youtube.com/watch?v=Y9wKMhxf2kU"
              target="_blank"
              className="btn play-btn"
              rel="noreferrer"
            >
              WATCH TRAILER
            </a>
            <button onClick={() => setIsOpen()} className="btn active-btn">
              <img src={play} alt="" />
              GET GAME
            </button>
          </div>
        </div>

        {/* <div className={`${!isLoad && 'hidden'} mob-address-div right-div`}>
            <div className='first-div mob-first'>
                <div className='main-address-div '>
                  <span className='address'>Copy Contract Address</span>
                  <img className='copy' src={copy} alt="" />
                </div>
                <span className='line'></span>
                <div className='social'>
                <img src={telegram} alt="" />
                <img src={twitter} alt="" />
                <img src={medium} alt="" />

                </div>
              </div>
            <div className='first-div mob-sec'>
                <div>
                  <span className='address'>SUPPORTED NETWORKS</span>
                </div>
                <span className='line'></span>
                <div className='social'>
                <img className='img' src={binance} alt="" />
                <img className='img' src={polygon} alt="" />

                </div>
              </div>
            </div> */}
      </div>
      <div className="sub-main banner-sub-main">
        <a href={GOOGLE_STORE_URL} target="_blank" rel="noreferre noreferrer">
          <img src={googleplay} alt="" />
        </a>
        <a target="_blank" rel="noreferre noreferrer" href={APPLE_STORE_URL}>
          <img src={apple} alt="battle craft game in apple store" />
        </a>
        <Link to="#">
          <img src={desktop} alt="" />
        </Link>
        {/* <a href={DESKTOP_APP_DOWNLOAD_URL} target="_blank" rel="noreferrer">
        </a> */}
        <a href="apk/BattleCraft.apk" target="_blank">
          <img src={apkt} alt="" />
        </a>
      </div>
      <img className="arrow-gif" src={arrow} alt="" />
      <img className="every" src={every} alt="" />

      <div className="supported-div">
        <span>SUPPORTED NETWORK</span>
        <hr />
        <div className="d-md-flex">
        <img src={bnb} className="mx-auto mx-md-2" alt="" />
        <img src={polygon} className="mx-auto mx-md-2" alt="" />
        </div>
      </div>
    </>
  );
};

export default Banner;
// https://drive.google.com/file/d/1WZPYuFRD0uU_fXkImZi8KJh5f3zcEUFk/view?usp=share_link
