import React from "react";

import loader from '../images/loader.gif';
export function SplashScreen() {
  return (
    <>
      <div className="splash-screen">
        <img
          src={loader}
          alt=""
        />
      </div>
    </>
  );
}
