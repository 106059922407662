import "./loader.css";

function Loader({ show = false, width = "100%", height = 24 }) {
  return (
    show && (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width, height }}
      >
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  );
}

export default Loader;
