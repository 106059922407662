import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./pages/Landing";
import MarketPlace from "./pages/MarketPlace";
import ScrollTop from "./ScrollTop";
import NFT from "./pages/NFT";
import { SplashScreen } from "./pages/SplashScreen";
import Commingsoon from "./pages/Commingsoon";
import Skyverse from "./pages/Skyverse";
import Audit from "./pages/Audit";
import AuthPage from "./pages/AuthPage";
import LeaderBoard from "./pages/GameLeaderBoard";
import ProtectedRoute from "./routes/ProtectedRoute";
import AppDeletionPolicy from "./pages/AppDeletionPolicy";
import AppPrivacyPolicy from "./pages/AppPrivacyPolicy";
import AppTermsAndConditions from "./pages/AppTermsAndConditions";

function App() {
  const routes = [
    {
      path: "/",
      element: Landing,
      key: "home",
    },
    {
      path: "/marketplace",
      element: Commingsoon,
      key: "Commingsoon",
    },
    {
      path: "/market-place",
      element: MarketPlace,
      key: "MarketPlace",
    },
    {
      path: "/skyverse",
      element: Skyverse,
      key: "Skyverse",
    },
    {
      path: "/nft/:id",
      element: NFT,
      key: "NFT",
    },
    {
      path: "/early-access",
      element: Audit,
      key: "Audit",
    },
    {
      path: "/leader-board",
      element: LeaderBoard,
      key: "LeaderBoard",
    },
  ];

  return (
    <>
      <ToastContainer autoClose={3500} hideProgressBar />
      <BrowserRouter>
        <ScrollTop />
        <Suspense fallback={<SplashScreen />}>
          <Switch>
            <Route key="password-protected" path="/password-protected" component={AuthPage} />

            {/* Conditional rendering for AppDeletionPolicy */}
            <Route
              key="app-deletion-policy"
              path="/app-deletion-policy"
              render={(props) => <AppDeletionPolicy />}
            />

            {/* Conditional rendering for AppPrivacyPolicy */}
            <Route
              key="app-privacy-policy"
              path="/app-privacy-policy"
              render={(props) => <AppPrivacyPolicy  />}
            />

            {/* Conditional rendering for AppTermsAndConditions */}
            <Route
              key="app-terms-of-service"
              path="/app-terms-of-service"
              render={(props) => <AppTermsAndConditions />}
            />

            {/* Render other routes */}
            {routes.map(({ path, element: Element, key }) => (
              <ProtectedRoute path={path} key={key}>
                <Element />
              </ProtectedRoute>
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
