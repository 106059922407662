import { useEffect } from "react";
import { useState } from "react";

const useShowNav = () => {
  const [isNav, setIsNav] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.pageYOffset > 128) {
        setIsNav(true);
      } else {
        setIsNav(false);
      }
    }

    window.addEventListener("scroll", handleResize);
    return () => window.removeEventListener("scroll", handleResize);
  }, []);

  return { isNav };
};

export default useShowNav;
