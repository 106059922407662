import React from "react";
import program from "../../images/audit/program.png";
import GetGameLinks from "../common/GetGameLinks";

const Program = () => {
  return (
    <div className="col-12 program mt-5">
      <div className="row no-gutters main">
        <img className="col-md-5 circle " alt="" src={program} />
        <div className="col-md-6 sub-hype">
          <h2>EARN POINTS</h2>
          <h1>REFERRAL PROGRAM</h1>
          <p>
            Want to help each other out? Spread the word of Referral program
            introduced by BattleCraft giving away free NFT spacecrafts, The top
            users with highest referral points will secure a chance to get free
            NFT spacecraft.
          </p>
          <p>DOWNLOAD NOW TO GET YOUR REFFERAL LINK</p>
          <GetGameLinks />
        </div>
      </div>
    </div>
  );
};

export default Program;
